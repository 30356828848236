import { AmbientLight, Color, MeshBasicMaterial, OrthographicCamera, PCFSoftShadowMap, PointLight, Scene, WebGLRenderer } from 'three';
import { Block } from './atom/Block';
import { Camera } from 'three/src/cameras/Camera';
import { levels } from './level';
import { Direction } from './Direction';
import { BlockType, TypeMap } from './atom/BlockType';
import { Interaction } from 'three.interaction';

export class Stage {
  private level: {
    boxes: Array<{ x: number; z: number }>;
    concretes: Array<{ x: number; z: number }>;
    player: { x: number; y: number; z: number };
    targets: Array<{ x: number; z: number }>;
  } = {} as any;
  private boxes: Block[] = [];
  private target: HTMLElement;
  private movements: number = 0;
  private levelNumber = Math.floor(Math.random() * 1000) % levels.length;
  private gameRunning: boolean = true;
  constructor() {
    this.scene = new Scene();
    this.target = document.getElementById('game-container') || document.body;
    this.scene.background = new Color(0xf4f4f4);
    let sizeX = this.target.clientWidth;
    let sizeY = this.target.clientHeight;
    let aspect = sizeX / sizeY;
    if (aspect < 1.15) {
      aspect = 1;
      sizeX = sizeY = Math.min(sizeY, sizeX);
    }

    let d = 5;
    this.camera = new OrthographicCamera(-d * aspect, d * aspect, d, -d, 1, 100);

    this.camera.position.set(5, 5, 5); // all components equal
    this.camera.lookAt(this.scene.position); // or the origin
    // this.camera = new PerspectiveCamera(75, window.innerWidth / window.innerHeight, 0.1, 1000);
    this.renderer = new WebGLRenderer();
    this.renderer.setSize(sizeX, sizeY);
    this.renderer.shadowMap.enabled = true;
    this.renderer.shadowMap.type = PCFSoftShadowMap;
    this.target.appendChild(this.renderer.domElement);
    new Interaction(this.renderer, this.scene, this.camera);
    this.time = new Date();
  }

  player?: Block;
  obstacles?: Array<Block>;
  time: Date;
  base?: Array<Block>;
  scene: Scene;
  camera: Camera;
  renderer: WebGLRenderer;

  nextLevel = () => {
    this.levelNumber = (this.levelNumber + 1) % levels.length;
    this.initLevel();
  };

  initLevel = () => {
    this.gameRunning = true;
    this.movements = 0;
    this.base = [];
    Block.boxCount = 0;
    this.level = JSON.parse(JSON.stringify(levels[this.levelNumber]));

    const sizeX = Math.max(...this.level.concretes.map((item) => item.x));
    const sizeZ = Math.max(...this.level.concretes.map((item) => item.z));
    Block.limits = { x: sizeX, z: sizeZ };

    new Array(sizeX + 1)
      .fill(null)
      .forEach((el, x) =>
        new Array(sizeZ + 1)
          .fill(null)
          .forEach((_el, z) =>
            this.base?.push(
              new Block(
                { x, y: 0, z },
                1,
                this.level.targets.some((target) => target.x === x && target.z === z) ? BlockType.TARGET : BlockType.CONCRETE
              )
            )
          )
      );
    this.obstacles = this.level.concretes.map((item) => new Block(item, 0.3));
    this.player = new Block(this.level.player, 1, BlockType.PLAYER);

    this.boxes = this.level.boxes.map((box) => new Block(box, 0.7, BlockType.BOX));

    const light = new PointLight(0xffffff, 1, 100);
    light.castShadow = true;
    light.shadow.mapSize.width = 2048; // default
    light.shadow.mapSize.height = 2048; // default
    light.shadow.camera.near = 0.5; // default
    light.shadow.camera.far = 500; // default
    light.position.set(0, 6, 6);
    // const helper = new CameraHelper(light.shadow.camera);

    this.scene.clear();
    this.scene.add(new AmbientLight(0x666666));
    this.scene.add(light);
    // this.scene.add(helper);
    this.scene.add(new Block({ x: sizeX + 3, y: -1, z: 3 }, 1, BlockType.ARROW_RIGHT, () => this.go(Direction.RIGHT)));
    this.scene.add(new Block({ x: sizeX + 3, y: -1, z: 4 }, 1, BlockType.ARROW_DOWN, () => this.go(Direction.DOWN)));
    this.scene.add(new Block({ x: sizeX + 3, y: -1, z: 5 }, 1, BlockType.ARROW_LEFT, () => this.go(Direction.LEFT)));
    this.scene.add(new Block({ x: sizeX + 2, y: -1, z: 4 }, 1, BlockType.ARROW_UP, () => this.go(Direction.UP)));
    this.scene.add(new Block({ x: sizeX + 2, y: -1, z: 5 }, 1, BlockType.RELOAD, () => this.initLevel()));
    this.base.forEach((box) => this.scene.add(box));
    this.obstacles.forEach((box) => this.scene.add(box));
    this.boxes.forEach((box) => this.scene.add(box));

    this.scene.add(this.player);
  };

  play = () => {
    if (this.movements > 100)
      if ((window as any).lost) {
        (window as any).lost();
      } else {
        console.log('please create "window.lost()" function');
      }
    const moving = [this.player, ...this.boxes].filter((item) => item?.canAnimate());
    moving.forEach((item) => item?.animate());
    if (moving.length === 0) {
      if (
        this.boxes.length > 0 &&
        this.level.targets.length > 0 &&
        this.gameRunning &&
        this.boxes.every((box) => this.level.targets.some((target) => target.x === box.data.x && target.z === box.data.z))
      ) {
        if ((window as any).won) {
          this.gameRunning = false;
          (window as any).won();
          console.log('window.won()', this.boxes, this.level.targets);
        }
      }
    }
    requestAnimationFrame(() => this.play());
    this.renderer.render(this.scene, this.camera);
  };
  go = (direction: Direction) => {
    const moving = [this.player, ...this.boxes].filter((item) => item?.canAnimate());
    if (moving.length > 0) return;
    let { x, z } = this.player?.data;
    let [nextX, nextZ] = [x, z];
    switch (direction) {
      case Direction.LEFT:
        z += 1;
        nextZ += 2;
        break;
      case Direction.RIGHT:
        z -= 1;
        nextZ -= 2;
        break;
      case Direction.UP:
        x -= 1;
        nextX -= 2;
        break;
      case Direction.DOWN:
        x += 1;
        nextX += 2;
        break;
    }
    if (this.obstacles?.some((item) => item.data.x === x && item.data.z === z)) return;
    const movingBoxes = this.boxes.filter((item) => item.data.x === x && item.data.z === z);
    if (movingBoxes.length > 0) {
      if ([...(this.obstacles || []), ...this.boxes].some((item) => item.data.x === nextX && item.data.z === nextZ)) return;
      movingBoxes.forEach((item) => {
        item.data.x = nextX;
        item.data.z = nextZ;
        const mat = item.material;
        console.log(mat);
        let newMat = [];
        if (this.level.targets.some((target) => target.x === nextX && target.z === nextZ && Array.isArray(mat))) {
          newMat = [
            new MeshBasicMaterial({ map: TypeMap.green }),
            new MeshBasicMaterial({ map: TypeMap.green }),
            (mat as Array<any>)[2],
            new MeshBasicMaterial({ map: TypeMap.green }),
            new MeshBasicMaterial({ map: TypeMap.green }),
            new MeshBasicMaterial({ map: TypeMap.green }),
          ];
        } else {
          newMat = [
            new MeshBasicMaterial({ map: TypeMap.crate }),
            new MeshBasicMaterial({ map: TypeMap.crate }),
            (mat as Array<any>)[2],
            new MeshBasicMaterial({ map: TypeMap.crate }),
            new MeshBasicMaterial({ map: TypeMap.crate }),
            new MeshBasicMaterial({ map: TypeMap.crate }),
          ];
        }
        item.material = newMat;
        item.updatePosition(300);
      });
    }
    if (this.player?.data.x !== nextX && this.player?.data.z !== nextZ) this.movements++;
    Object.assign(this.player?.data, { x, z });
    this.player?.updatePosition(300);
  };
}
