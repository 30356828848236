import { TextureLoader } from 'three';

export enum BlockType {
  ARROW_DOWN,
  ARROW_LEFT,
  ARROW_RIGHT,
  ARROW_UP,
  BOX,
  CONCRETE,
  PLAYER,
  RELOAD,
  TARGET,
}

export const TypeMap: any = {
  angular: new TextureLoader().load('textures/angular.png'),
  arrow: new TextureLoader().load('textures/arrow.png'),
  blank: new TextureLoader().load('textures/blank.png'),
  container: new TextureLoader().load('textures/container.png'),
  crate: new TextureLoader().load('textures/crate.png'),
  green: new TextureLoader().load('textures/green.png'),
  java: new TextureLoader().load('textures/java.png'),
  js: new TextureLoader().load('textures/js.png'),
  python: new TextureLoader().load('textures/python.png'),
  react: new TextureLoader().load('textures/react.png'),
  reload: new TextureLoader().load('textures/reload.png'),
  small: new TextureLoader().load('textures/blank.png'),
};
