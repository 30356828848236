import { BlockType, TypeMap } from './BlockType';
import { BoxGeometry, CylinderGeometry, Mesh, MeshBasicMaterial, MeshPhongMaterial, SphereGeometry, Vector3 } from 'three';

export class Block extends Mesh {
  static boxCount = 0;

  blockType: BlockType;
  data: any;
  static limits: { x: number; z: number };
  private readonly height: number;
  moveTime?: number;
  private target?: Vector3;
  private lastPostion?: Vector3;
  private delay: number = 1000;

  constructor(data: any, height: number = 1, blockType = BlockType.CONCRETE, listener?: () => void) {
    super(Block.getGeometry(blockType, height), Block.getMaterial(blockType, height));
    switch (blockType) {
      case BlockType.ARROW_UP:
      case BlockType.RELOAD:
        this.rotateZ(0.5 * Math.PI);
        break;
      case BlockType.ARROW_RIGHT:
        this.rotateY(1.5 * Math.PI);
        this.rotateZ(0.5 * Math.PI);
        break;
      case BlockType.ARROW_LEFT:
        this.rotateY(0.5 * Math.PI);
        this.rotateZ(0.5 * Math.PI);
        break;
      case BlockType.ARROW_DOWN:
        this.rotateY(Math.PI);
        this.rotateZ(0.5 * Math.PI);
        break;
    }

    // this.castShadow = true;
    // this.receiveShadow = true;

    this.data = data;
    this.height = height;
    this.blockType = blockType;
    this.updatePosition();
    if (listener) {
      // @ts-ignore
      this.on('click', listener);
      // @ts-ignore
      this.on('touchend', listener);
    }
  }

  updatePosition = (delay = 0) => {
    const size = Math.max(Block.limits.x, Block.limits.z);
    const halfSize = size / 2;
    if (this.data.x !== undefined && this.data.z !== undefined) {
      this.target = new Vector3(
        this.data.x - halfSize,
        (this.data.y === undefined ? 1 : this.data.y) - 0.5 + this.height / 2,
        this.data.z - halfSize
      );
      if (delay === 0) {
        this.position.set(this.target.x, this.target.y, this.target.z);
      } else {
        this.delay = delay;
        this.lastPostion = this.position.clone();
        this.moveTime = new Date().getTime();
      }
    }
  };

  canAnimate = () => this.lastPostion && this.moveTime && this.target;

  animate = () => {
    if (!this.lastPostion || !this.moveTime || !this.target) return;
    const now = new Date().getTime();

    if (now - this.moveTime > this.delay) {
      this.lastPostion = undefined;
      this.moveTime = undefined;
      this.position.set(this.target.x, this.target.y, this.target.z);
      return;
    }
    const speed = (now - this.moveTime) / this.delay;
    const middle = (start: number, end: number) => (end - start) * speed + start;
    this.position.set(
      middle(this.lastPostion.x, this.target.x),
      middle(this.lastPostion.y, this.target.y),
      middle(this.lastPostion.z, this.target.z)
    );
  };

  private static getGeometry(blockType: BlockType, height: number) {
    switch (blockType) {
      case BlockType.PLAYER:
        const RADIUS = 0.2;
        const HEIGHT = 0.4;

        const cylinder = new CylinderGeometry(RADIUS, RADIUS, HEIGHT, 60, 8);
        const sphere = new SphereGeometry(RADIUS, 60, 30);
        const sphere2 = new SphereGeometry(RADIUS, 60, 30);
        sphere2.translate(0, HEIGHT + RADIUS, 0);
        sphere.translate(0, HEIGHT - 1.1 * RADIUS, 0);
        cylinder.mergeMesh(new Mesh(sphere));
        cylinder.mergeMesh(new Mesh(sphere2));
        cylinder.mergeVertices(4);
        return cylinder;
      case BlockType.CONCRETE:
        return new BoxGeometry(1, height, 1);
      default:
        return new BoxGeometry(1, height, 1);
    }
  }

  private static getMaterial(blockType: BlockType, height = 1) {
    const boxMaterials = [
      new MeshBasicMaterial({ map: TypeMap.python }),
      new MeshBasicMaterial({ map: TypeMap.react }),
      new MeshBasicMaterial({ map: TypeMap.js }),
      new MeshBasicMaterial({ map: TypeMap.java }),
      new MeshBasicMaterial({ map: TypeMap.angular }),
    ];

    switch (blockType) {
      case BlockType.CONCRETE:
        const txs = [
          new MeshBasicMaterial({ map: TypeMap.blank }),
          new MeshBasicMaterial({ map: TypeMap.blank }),
          new MeshBasicMaterial({ map: TypeMap.blank }),
          new MeshBasicMaterial({ map: TypeMap.blank }),
          new MeshBasicMaterial({ map: TypeMap.blank }),
          new MeshBasicMaterial({ map: TypeMap.blank }),
        ];

        if (height < 1) {
          const smallTx = TypeMap.small;
          smallTx.repeat.set(1, height);
          return txs.map((tx, ind) => {
            if (![2].includes(ind)) {
              tx.map = smallTx;
            }
            return tx;
          });
        }

        return txs;
      case BlockType.BOX:
        Block.boxCount = (Block.boxCount + 1) % boxMaterials.length;
        return [
          new MeshBasicMaterial({ map: TypeMap.crate }),
          new MeshBasicMaterial({ map: TypeMap.crate }),
          boxMaterials[Block.boxCount],
          new MeshBasicMaterial({ map: TypeMap.crate }),
          new MeshBasicMaterial({ map: TypeMap.crate }),
          new MeshBasicMaterial({ map: TypeMap.crate }),
        ];
      case BlockType.TARGET:
        return new MeshBasicMaterial({ map: TypeMap.container });
      case BlockType.PLAYER:
        return new MeshPhongMaterial({ color: 0x506bd8, specular: 0x222222, shininess: 20 });
      case BlockType.RELOAD:
        return [new MeshBasicMaterial({ map: TypeMap.reload })];
      case BlockType.ARROW_DOWN:
      case BlockType.ARROW_LEFT:
      case BlockType.ARROW_RIGHT:
      case BlockType.ARROW_UP:
        return [new MeshBasicMaterial({ map: TypeMap.arrow })];
      default:
        return new MeshBasicMaterial({ map: TypeMap.blank });
    }
  }
}
