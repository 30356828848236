import { Stage } from './Stage';
import { Controls } from './Controls';

(() => {
  const stage = new Stage();
  stage.initLevel();
  stage.play();
  const controls = new Controls();
  controls.apply(stage);
  (window as any).nextLevel = stage.nextLevel;
  (window as any).restart = stage.initLevel;
})();
