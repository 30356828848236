export const levels = [
  {
    boxes: [
      { x: 2, y: 0.85, z: 5 },
      { x: 2, y: 0.85, z: 3 },
    ],
    concretes: [
      { x: 0, z: 0 },
      { x: 0, z: 1 },
      { x: 0, z: 2 },
      { x: 0, z: 3 },
      { x: 0, z: 4 },
      { x: 0, z: 5 },
      { x: 0, z: 6 },
      { x: 1, z: 6 },
      { x: 2, z: 6 },
      { x: 2, z: 7 },
      { x: 3, z: 7 },
      { x: 4, z: 7 },
      { x: 5, z: 7 },
      { x: 6, z: 7 },
      { x: 6, z: 6 },
      { x: 6, z: 5 },
      { x: 6, z: 4 },
      { x: 6, z: 3 },
      { x: 6, z: 2 },
      { x: 5, z: 2 },
      { x: 5, z: 1 },
      { x: 4, z: 1 },
      { x: 4, z: 0 },
      { x: 3, z: 0 },
      { x: 2, z: 0 },
      { x: 1, z: 0 },
      { x: 3, z: 3 },
      { x: 4, z: 5 },
    ],
    targets: [
      { x: 1, z: 1 },
      { x: 5, z: 6 },
    ],
    player: { x: 1, y: 0.75, z: 1 },
  },
  {
    boxes: [
      { x: 3, y: 0.85, z: 6 },
      { x: 6, y: 0.85, z: 8 },
      { x: 8, y: 0.85, z: 8 },
      { x: 8, y: 0.85, z: 5 },
      { x: 8, y: 0.85, z: 2 },
    ],
    concretes: [
      { x: 3, z: 0 },
      { x: 4, z: 0 },
      { x: 5, z: 0 },
      { x: 6, z: 0 },
      { x: 7, z: 0 },
      { x: 8, z: 0 },
      { x: 9, z: 0 },
      { x: 9, z: 1 },
      { x: 9, z: 2 },
      { x: 9, z: 3 },
      { x: 9, z: 4 },
      { x: 10, z: 4 },
      { x: 10, z: 5 },
      { x: 10, z: 6 },
      { x: 10, z: 7 },
      { x: 10, z: 8 },
      { x: 10, z: 9 },
      { x: 10, z: 10 },
      { x: 9, z: 10 },
      { x: 8, z: 10 },
      { x: 7, z: 10 },
      { x: 6, z: 10 },
      { x: 5, z: 10 },
      { x: 5, z: 9 },
      { x: 4, z: 9 },
      { x: 3, z: 9 },
      { x: 3, z: 8 },
      { x: 2, z: 8 },
      { x: 1, z: 8 },
      { x: 0, z: 8 },
      { x: 0, z: 7 },
      { x: 0, z: 6 },
      { x: 0, z: 5 },
      { x: 0, z: 4 },
      { x: 0, z: 3 },
      { x: 1, z: 3 },
      { x: 2, z: 3 },
      { x: 2, z: 2 },
      { x: 3, z: 2 },
      { x: 3, z: 1 },
      { x: 2, z: 5 },
      { x: 2, z: 6 },
      { x: 5, z: 6 },
      { x: 6, z: 6 },
      { x: 5, z: 5 },
      { x: 5, z: 7 },
      { x: 6, z: 5 },
      { x: 8, z: 6 },
      { x: 8, z: 7 },
      { x: 5, z: 2 },
      { x: 6, z: 2 },
      { x: 7, z: 2 },
      { x: 5, z: 3 },
      { x: 6, z: 3 },
      { x: 7, z: 3 },
    ],
    targets: [
      { x: 5, z: 8 },
      { x: 8, z: 9 },
      { x: 8, z: 8 },
      { x: 7, z: 4 },
      { x: 4, z: 4 },
    ],
    player: { x: 4, y: 0.75, z: 8 },
  },
  {
    boxes: [
      { x: 2, y: 0.85, z: 2 },
      { x: 3, y: 0.85, z: 2 },
      { x: 4, y: 0.85, z: 4 },
      { x: 6, y: 0.85, z: 4 },
      { x: 4, y: 0.85, z: 6 },
      { x: 3, y: 0.85, z: 7 },
    ],
    concretes: [
      { x: 2, z: 0 },
      { x: 3, z: 0 },
      { x: 4, z: 0 },
      { x: 5, z: 0 },
      { x: 6, z: 0 },
      { x: 7, z: 0 },
      { x: 7, z: 1 },
      { x: 7, z: 2 },
      { x: 7, z: 3 },
      { x: 7, z: 4 },
      { x: 7, z: 5 },
      { x: 7, z: 6 },
      { x: 6, z: 6 },
      { x: 6, z: 7 },
      { x: 5, z: 7 },
      { x: 4, z: 7 },
      { x: 4, z: 8 },
      { x: 4, z: 9 },
      { x: 3, z: 9 },
      { x: 2, z: 9 },
      { x: 1, z: 9 },
      { x: 0, z: 9 },
      { x: 0, z: 8 },
      { x: 0, z: 7 },
      { x: 0, z: 6 },
      { x: 0, z: 5 },
      { x: 0, z: 4 },
      { x: 0, z: 3 },
      { x: 0, z: 2 },
      { x: 0, z: 1 },
      { x: 1, z: 1 },
      { x: 2, z: 1 },
      { x: 2, z: 3 },
      { x: 2, z: 5 },
      { x: 2, z: 6 },
      { x: 2, z: 7 },
      { x: 5, z: 2 },
      { x: 5, z: 3 },
      { x: 5, z: 4 },
    ],
    targets: [
      { x: 1, z: 7 },
      { x: 3, z: 4 },
      { x: 4, z: 1 },
      { x: 6, z: 1 },
      { x: 6, z: 3 },
      { x: 5, z: 6 },
    ],
    player: { x: 2, y: 0.75, z: 8 },
  },
];
