import { Stage } from './Stage';
import { Direction } from './Direction';

export class Controls {
  private stage?: Stage;
  constructor() {
    document.addEventListener('keyup', (event) => {
      switch (event.key) {
        case 'Down': // IE/Edge specific value
        case 'ArrowDown':
          this.stage?.go(Direction.DOWN);
          break;
        case 'Up': // IE/Edge specific value
        case 'ArrowUp':
          this.stage?.go(Direction.UP);
          // Do something for "up arrow" key press.
          break;
        case 'Left': // IE/Edge specific value
        case 'ArrowLeft':
          this.stage?.go(Direction.LEFT);

          // Do something for "left arrow" key press.
          break;
        case 'Right': // IE/Edge specific value
        case 'ArrowRight':
          this.stage?.go(Direction.RIGHT);

          // Do something for "right arrow" key press.
          break;
        case 'Enter':
          this.stage?.initLevel();
          break;
        case 'Esc': // IE/Edge specific value
        case 'Escape':
          // Do something for "esc" key press.
          break;
        default:
          return; // Quit when this doesn't handle the key event.
      }
      event.preventDefault();
    });
  }

  apply(stage: Stage) {
    this.stage = stage;
  }
}
